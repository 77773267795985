import React from 'react';
import { graphql } from 'gatsby';
import { Hat } from 'components/Hat/Hat';
import { Hello, Feed, Seo, SeoHidden, HelloSimple } from 'components';

export default ({
  data: {
    page: {
      frontmatter: { title, seo_title, full_title, og, seo_h1, description, heading_text }
    },
    feed
  },
  pageContext: { id, list }
}) => {
  return (
    <>
      {seo_h1 ? (
        <>
          {heading_text ? (
            <HelloSimple title={full_title || title} descr={heading_text} />
          ) : (
            <Hat title={full_title || title} />
          )}
          <SeoHidden>
            <h1>{seo_h1}</h1>
          </SeoHidden>
        </>
      ) : (
        <Hello title={full_title || title} as={'h1'} />
      )}
      <Seo title={seo_title || full_title || title} og={og} description={description} />
      <Feed feed={feed} list={list} />
    </>
  );
};

export const pageQuery = graphql`
  query($id: String!, $list: [String]) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        title
        full_title
        seo_title
        seo_h1
        og
        description
        heading_text
      }
    }

    feed: allMdx(filter: { fields: { id: { in: $list } } }) {
      ...CardProject
      ...CardArticle
      ...CardJob
      ...CardService
      ...CardSpec
    }
  }
`;
